import { FC, createRef, memo } from 'react';
import styles from '../../../ExpensionMap.module.scss';
import cx from 'classnames';
import { useExpansionMapHelper } from '../../../helper';
import { Districts } from '../../../../../constants/districts';

export const Longerich: FC = memo(() => {
  const ref = createRef<SVGTextElement>();
  const {
    district,
    expansionStatusClass,
    isSelected,
    handleClick,
    handleHover,
  } = useExpansionMapHelper({
    district: Districts.Longerich,
    ref,
  });

  return (
    <>
      <g
        className={cx(styles.districtWrapper, {
          [styles.isSelected]: isSelected,
        })}
        id={district}
        onClick={handleClick}
        onMouseEnter={handleHover}
      >
        <path
          className={cx(styles[expansionStatusClass], styles.districtArea)}
          aria-label="Longerich"
          d="M518,422l-5,6l-10-6l-16,2l-34-2l-16,10l-25,22l-14-26l-28-28c20-16,52.1-41.8,72-58c0,0,5.7-10.2,5.7-10.2 l0.3,0.2l32,66l10,2v12l14-4L518,422z"
        />
        <text
          className={styles.districtName}
          ref={ref}
          transform="matrix(1 0 0 1 404.1307 402.619)"
        >
          Longerich
        </text>
      </g>
    </>
  );
});
